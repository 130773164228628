.hero {
   width: 100%;
   height: 70vh;
   min-height: 500px;
   position: relative;
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#72b4fe+10,90fff6+80 */
   background: rgb(114,180,254); /* Old browsers */
   background: -moz-linear-gradient(top,  rgba(114,180,254,1) 10%, rgba(144,255,246,1) 80%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(114,180,254,1) 10%,rgba(144,255,246,1) 80%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(114,180,254,1) 10%,rgba(144,255,246,1) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#72b4fe', endColorstr='#90fff6',GradientType=0 ); /* IE6-9 */
	@include tablet {
		height: 900px;
	}				
      
   &-text {
      z-index: 100;
      position: absolute;
      left: 0;
      bottom: -5.5rem;
      width: calc(100% - 3rem);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+15,ffffff+15,ffffff+60&0.9+15,1+60 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,0.8) 15%, rgba(255,255,255,1) 60%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0.8) 15%,rgba(255,255,255,1) 60%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,0.8) 15%,rgba(255,255,255,1) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      text-align: center;
      padding: 2rem;
      margin: 0 1.5rem;
      -webkit-box-shadow: 0 20px 20px 0 rgba(0,0,0,.1);
      box-shadow: 0 20px 20px 0 rgba(0,0,0,.1);
      @include tablet {
         left: 1.5rem;
         width: 50%;
         text-align: left;
         bottom: 7rem;
         top: initial;
         background: none;
         padding: 0;
         margin: 0;
         -webkit-box-shadow: none;
         box-shadow: none;
      }
      @include tablet-wide {
         left: 4rem;
         bottom: 8rem;
         width: 45%;      
      }
      @include desktop {
         left: 6rem;     
         bottom: 9rem;
      }
      @include md {
         left: 8rem;     
      }
      &-headline {
         font-family: $headline;
         font-weight: 500;
         color: $blue;
         font-size: 1.25rem;
         @include tablet {
         font-size: 1.85rem;
         }
         @include tablet-wide {
         font-size: 2.0rem;
         }
         @include desktop {
         font-size: 2.35rem;
         }
         @include md {
         font-size: 2.85rem;
         }
      }
      &-buttons {
         margin-top: 1rem;
         @include tablet {
            margin-top: 1.5rem;
         }
         &-donations {
            display: block;
            text-shadow: none;
            @include tablet {
               display: inline-block;
            }
            & .button {
               font-size: 1.2rem;
               text-shadow: none;
               padding: 1rem 1.5rem;
               color: $white !important;
               @include tablet {
                  font-size: 2.0rem;
                  padding: 1.0rem 2rem;
               }
            }
         }
         &-link {
            display: block;
            margin: 1rem 0 0 0;
            font-size: 1.2rem;
            font-weight: 400;
            text-decoration: underline;
            @include tablet {
               font-size: 1.65rem;
               margin: 1.5rem 0 0 0;
            }
            @include desktop {
               display: inline-block;
               margin: 0 0 0 4rem;
               position: absolute;
               transform: translateY(70%);
            }
            & a {
               transition: $slow;
            }
            & a:hover {
               color: $lightblue !important;
            }
            & a:hover:after {
               
            }
         }
      }
   }
   &-photo {
      position: relative;
      width:  100%;
      height: 70vh;
      min-height: 500px;
      right: 0;
      bottom: 0;
      z-index: 99;
      @include tablet {
         height: 900px;
      }
      &-pic {
         width:  100%;
         height: 70vh;
         min-height: 500px;
         @include tablet {
            height: 900px;
         }
      }
      &-img {
         display: block;
         position: absolute;
         right: 0;
         bottom: 0;
         height: 60vh;
         min-height: 500px;
         @include tablet {
            right: 0;
            height: 650px;
         }
         @include tablet-wide {
            right: 0;
            height: 750px;
         }
         @include desktop {
            height: auto;
         }
      }
   }
   &-bottom-border {
      z-index: 98;
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#febdfd+20,6ca5e4+90 */
      background: rgb(254,189,253); /* Old browsers */
      background: -moz-linear-gradient(left,  rgba(254,189,253,1) 20%, rgba(108,165,228,1) 90%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  rgba(254,189,253,1) 20%,rgba(108,165,228,1) 90%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  rgba(254,189,253,1) 20%,rgba(108,165,228,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#febdfd', endColorstr='#6ca5e4',GradientType=1 ); /* IE6-9 */
   }
}

.hero .slick-list {
   height: calc(70vh + 7rem);
   @include tablet {
      height: auto;
   }
}

.slide {
   position: relative; 
   width: 100%;
   max-width: 2000px;
   height: 70vh;
   opacity: 1;
   z-index:99; 
   @include tablet {
		height: 900px;
	}
} 
