main {
   margin-top: 5.5rem;
   @include tablet {
      margin-top: 0;
   }
}

.features-top {
   @extend %clearfix;
   @extend %block-padding-top-small;
   @extend %block-padding-bottom-small;
   @extend %block-margin-sides;
   text-align: center;
   max-width: $max-width;
   @include desktop {
      margin: 0 auto;
   }
}

.tweets {
   @extend %clearfix;
   @extend %block-padding-top-small;
   @extend %block-padding-bottom-small;
   @extend %block-margin-sides;
   text-align: center;
   max-width: $desktop;
   @include desktop {
      margin: 0 auto;
   }
}

.promos-bottom {
   @extend %clearfix;
   @extend %block-padding-top-small;
   @extend %block-margin-sides;
   text-align: center;
   max-width: $desktop;
   @include desktop {
      margin: 0 auto;
   }
}
