.partners {
   font-size: 1.1rem;
   @extend %clearfix;
   @extend %block-padding-top-small;
   @extend %block-padding-bottom-small;
   text-align: center;
   max-width: $max-width;
   margin: 1.5rem;
   transition: $slow;
   @include tablet  {
      font-size: 1.2rem;
      margin: 0 auto;
   }
   .partners-container {
   }
   .partners-item {
      transition: $slow;
      display: block;
      padding: 1rem;
      @include tablet {
         display: inline-block;
      }
      @include desktop {
         padding: 2rem;
      }
   }
   .partners-link {
      display: inline-block;
      transition: $slow;
      &:hover {
         transform: scale(1.2);
      }
   }
   .partners-logo {
   }
}
