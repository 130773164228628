.tweets {
   font-size: 1.1rem;
   @include tablet {
      font-size: 1.2rem;
   }
   &-grid {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: stretch;
      align-content: stretch;
   }
   &-item {
      position: relative;
      flex: initial;
      text-align: left;
      padding: 1.0rem;
      display: inline-block;
      transition: $slow;
      @include tablet {
         flex: 1;
         padding: 1.5rem;      
      }
      &:hover {
         box-shadow: $shadow;
         transform: scale(1.1);
      }
   }
   &-date {
      font-size: .8rem;
      text-transform: uppercase;
      color: $gray;
      display: block;
      margin-bottom: .25rem;
   }
   &-headline {
      line-height: 1.5rem;
   }
   &-headline a {
      color: $red !important;
   }
}
